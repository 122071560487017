* {
    outline: none;
    padding: 0;
    margin: 0;
}

html {
    height: 100%;
}

body {
    min-height: 100%;
    /* background: #f9f9f9 !important; */
}

body.site-lang-ua * {
  font-family: 'Ubuntu' !important;
}

#root {
    padding: 142px 0 0;
}

.pl-m-0 {
  margin: 0 !important;
}

.pl-ml-2 {
  margin-left: 20px !important;
}

.pl-mt-05 {
  margin-top: 5px !important;
}

.pl-mr-1 {
  margin-right: 10px !important;
}

.pl-mt-2 {
  margin-top: 20px !important;
}

.pl-mb-3 {
  margin-bottom: 30px !important;
}

.pl-mb-35 {
  margin-bottom: 35px !important;
}

.pl-ml-6 {
  margin-left: 60px !important;
}

.pl-mb-8 {
  margin-bottom: 80px !important;
}

@media screen and (max-width: 1024px) {
  .pl-mb-8 {
    margin-bottom: 35px !important;
  }
}

.pl-mb-10 {
  margin-bottom: 100px !important;
}

.pl-mt-21 {
  margin-top: 210px !important;
}

.pl-mb-21 {
  margin-bottom: 210px !important;
}

.pl-pb-2 {
  padding-bottom: 20px !important;
}

.pl-pb-35 {
  padding-bottom: 35px !important;
}

.pl-pb-4 {
  padding-bottom: 40px !important;
}

.pl-container {
  max-width: 1200px;
  width: 100%;
  margin: auto;
  position: relative;
}

.pl-left {
  justify-content: flex-start !important;
}

.pl-right {
  justify-content: flex-end !important;
}

.pl-text-center {
  text-align: center !important;
}

.pl-text-left {
  text-align: left !important;
}

.pl-text-right {
  text-align: right !important;
}

.pl-block-title {
  font-family: 'OdinRounded', sans-serif;
  font-size: 60px;
  color: #b3e830;
  line-height: 0.8;
}

.ant-layout {
  background-color: #f9f9f9 !important;
  background-color: #fff !important;
}

.ant-select-dropdown-menu-item {
  text-align: center !important;
  padding: 5px !important;
}

.ant-select-selection__rendered {
  margin: 0 0 0 11px !important;
}

.ant-input,
.ant-select-selection {
  border-radius: 25px !important;
}

.ant-input:focus,
.ant-input:hover {
  border-color: #b3e830 !important;
  box-shadow: 0 0 0 2px rgba(8, 249, 48, 0.2) !important;
}

.pl-singleFooter section {
  min-height: calc(100vh - 200px);
}

@media screen and (min-width: 768px) {
  .pl-singleFooter section {
    min-height: calc(100vh - 375px);
  }
}